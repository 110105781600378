import { ArrowLeftIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import { Button } from "./button.tsx";

export function BackButton() {
  const navigate = useNavigate();

  return (
    <Button
      color="light"
      data-testid="backbutton"
      onClick={() => navigate(-1)}
    >
      <ArrowLeftIcon />
      <span className="hidden sm:inline">Go Back</span>
    </Button>
  );
}
