import { Params } from "react-router-dom";
import { z } from "zod";
import { HttpMethod, request, Urls } from "../api.ts";
import { RecipeIngredientSchema, RecipeStepSchema } from "./recipe-detail.lib.ts";

export const CookMealSchema = z.object({
  id: z.string().nullable(),
  title: z.string(),
  time: z.string().datetime({ offset: true }),
  date: z.string(),
  dateDisplay: z.string(),
  timeDisplay: z.string(),
});

/** A meal when presented for cooking. */
export type CookMealType = z.infer<typeof CookMealSchema>;

export const CookRecipeSchema = z.object({
  scheduledRecipeId: z.string(),
  recipeId: z.string(),
  title: z.string(),
  description: z.string(),
  sourceUrl: z.string().nullable(),
  courses: z.array(z.string()),
  yieldsServings: z.number().int().nullable(),
  ingredients: z.array(RecipeIngredientSchema),
  steps: z.array(RecipeStepSchema),
});

/** A recipe when presented for cooking. */
export type CookRecipeType = z.infer<typeof CookRecipeSchema>;

export const CookMealResponseSchema = z.object({
  meal: CookMealSchema,
  recipes: z.array(CookRecipeSchema),
  // Track whether ingredients and steps are checked off.
  // Keys are scheduledRecipeId. Value are lists of recipeIngredientId or recipeStepId.
  checkedItems: z.record(z.string(), z.array(z.string())),
});

export type CookMealResponseType = z.infer<typeof CookMealResponseSchema>;

export async function loader({
  params,
}: {
  params: Params<"mealId">;
}): Promise<CookMealResponseType | Response> {
  const url = Urls.CookMeal.replace("{mealId}", params.mealId!);
  const resp = await request(HttpMethod.GET, url);

  if (resp.status !== 200) {
    return resp;
  }

  const payload = await resp.json();
  return CookMealResponseSchema.parse(payload.data);
}

export async function setScheduledRecipeState(
  scheduledRecipeId: string,
  ingredientOrStepId: string,
  checked: boolean,
) {
  const url = Urls.CookState.replace("{scheduledRecipeId}", scheduledRecipeId!);
  const data = { objectId: ingredientOrStepId, checked: checked };
  const resp = await request(HttpMethod.POST, url, data);
  if (!resp.ok) {
    throw new Error("Unable to schedule the recipe");
  }
}
