import { PencilIcon } from "@heroicons/react/20/solid";
import { Link, useLoaderData } from "react-router-dom";
import { Button } from "../components/button.tsx";
import { usePageTitle } from "../utils.ts";
import { DayMenu, MealType, ScheduledRecipeType } from "./plan.lib.ts";

function Day({ day }: { day: DayMenu }) {
  return (
    <>
      <div className="mb-4 mt-10 flex flex-row items-baseline justify-between">
        <div className="text-4xl font-semibold text-theme-normal">{day.title}</div>
        <div className="text-end text-2xl font-semibold text-theme-low-contrast">
          {day.subTitle}
        </div>
      </div>
      {day.meals.map((meal) =>
        // For this page, only show meals that have recipes.
        meal.recipes.length > 0 ? (
          <Meal
            key={meal.id}
            meal={meal}
            date={day.date}
          />
        ) : null,
      )}
    </>
  );
}

function Meal({ meal, date }: { meal: MealType; date: string }) {
  return (
    <div className="mb-10">
      {/* Meal heading */}
      <div className="mb-2 flex flex-row items-baseline justify-between font-semibold text-theme-low-contrast">
        <div className="text-lg uppercase tracking-wide">{meal.title}</div>
        <div className="text-lg">{meal.timeDisplay}</div>
      </div>
      {/* Meal images */}
      <div className="flex w-full flex-row gap-2 overflow-hidden">
        {meal.recipes.map((sr) => (
          <div
            key={sr.id}
            className="shrink-0 sm:shrink"
          >
            <img
              className="mb-2 h-20 max-h-20 w-20 max-w-20 rounded-md object-cover md:h-32 md:max-h-32 md:w-32 md:max-w-32"
              src={sr.imageUrl}
            />
          </div>
        ))}
      </div>
      {/* Meal summary */}
      <div>{meal.recipes.length > 0 && <MainRecipe recipe={meal.recipes[0]} />}</div>
      <div className="mt-2 flex flex-row justify-between align-top">
        <div>{meal.extraRecipesDisplay || ""}</div>
        <Button href={`/cook/${meal.id}`}>Start Cooking</Button>
      </div>
      <Link
        to={`/menu/${date}`}
        className="mt-4 block border-2 border-dotted border-theme-normal/50 p-2 text-center font-semibold text-theme-normal/50"
      >
        <PencilIcon className="inline w-4" /> Change Menu
      </Link>
    </div>
  );
}

function MainRecipe({ recipe }: { recipe: ScheduledRecipeType }) {
  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className="text-xl font-bold text-theme-muted">{recipe.title}</div>
        {recipe.totalTimeDisplay && <div className="text-end">{recipe.totalTimeDisplay}</div>}
      </div>
    </div>
  );
}

export function PlanPage() {
  usePageTitle("Plan");
  const days = useLoaderData() as DayMenu[];

  if (!days) {
    return "";
  }

  return (
    <div data-testid="page:plan">
      {days.map((day) => (
        <Day
          key={day.date}
          day={day}
        />
      ))}
    </div>
  );
}
