import { Params, replace } from "react-router-dom";
import { z } from "zod";
import { request as doRequest, HttpMethod, Urls } from "../api.ts";
import { RecipeSummarySchema } from "./recipe-list.lib.ts";

export const AddScheduledRecipeResponseSchema = z.object({
  recipes: z.array(RecipeSummarySchema),
});

/** The response data for the add scheduled recipe selector. */
export type AddScheduledRecipeResponseType = z.infer<typeof AddScheduledRecipeResponseSchema>;

/** Loader for the AddScheduledRecipe page. */
export async function loader({
  params,
}: {
  params: Params<"mealId" | "menuDate">;
}): Promise<AddScheduledRecipeResponseType | Response> {
  const url = Urls.AddScheduledRecipe.replace("{mealId}", params.mealId!);
  const resp = await doRequest(HttpMethod.GET, url);
  if (resp.status !== 200) {
    return resp;
  }

  const payload = await resp.json();
  return AddScheduledRecipeResponseSchema.parse(payload.data);
}

/**
 * Action for AddScheduledRecipe page.
 *
 * Makes an API call to add the chosen recipe to the meal.
 */
export async function action({
  params,
  request,
}: {
  params: Params<"mealId" | "menuDate">;
  request: Request;
}) {
  const url = Urls.AddScheduledRecipe.replace("{mealId}", params.mealId!);
  const payload = await request.json();
  const resp = await doRequest(HttpMethod.POST, url, payload);
  if (!resp.ok) {
    // TODO: do something?
  }

  return replace(`/menu/${params.menuDate!}`);
}
