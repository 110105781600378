import clsx from "clsx";
import { useContext, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Button } from "../components/button";
import { Logo } from "../components/logo";
import { SessionContext } from "../session-context";
import { OnboardingStatus, Page } from "../types";
import { UserContext } from "../user-context";
import { usePageTitle } from "../utils";
import { followCollection, OnboardingCollection } from "./onboarding.lib";

type CollectionProps = {
  collection: OnboardingCollection;
  setGlobalFollowed: (item: string, status: boolean) => void;
};

function Collection({ collection, setGlobalFollowed }: CollectionProps) {
  const [followed, setFollowed] = useState(false);
  const user = useContext(UserContext);

  async function toggleFollow() {
    const ret = await followCollection(user.selectedHousehold?.id || "", collection.id);

    if (ret.ok) {
      const payload = await ret.json();
      setFollowed(payload.data !== null);
      setGlobalFollowed(collection.id, payload.data !== null);
    }
  }

  return (
    <div
      className="group space-y-4 hover:cursor-pointer"
      onClick={toggleFollow}
    >
      <h2 className="mt-6 text-lg font-medium">{collection.title}</h2>
      <div
        className={
          followed
            ? "rounded-lg border-4 border-solid border-green-500 bg-green-500 shadow-lg"
            : "rounded-lg border-4 border-solid border-transparent"
        }
      >
        <img
          className={clsx(
            "shadowgroup-hover:opacity-75 oject-cover pointer-events-none w-full rounded-md",
          )}
          src={collection.imageUrl}
        />
      </div>
    </div>
  );
}

export function AddCollectionsPage() {
  const { setSession } = useContext(SessionContext);
  const collections = useLoaderData() as OnboardingCollection[];
  usePageTitle("Start your Cookbook");

  const [followedItems, setFollowedItems] = useState(
    collections.map((item) => {
      return { id: item.id, followed: false };
    }),
  );

  const anySelected = followedItems.reduce((accu, item) => {
    return accu || item.followed || false;
  }, false);

  let button;
  if (!anySelected) {
    button = <Button disabled={true}>Add some collections</Button>;
  } else {
    button = (
      <Button
        onClick={() => {
          setSession((s) => {
            return {
              options: s.options,
              user: s.user
                ? { ...s.user, onboardingStatus: OnboardingStatus.NeedsPlan }
                : undefined,
            };
          });
        }}
        // Send them to the plan loading page
        href={Page.OnboardingPlanLoading}
      >
        Let&apos;s Go!
      </Button>
    );
  }

  const trackGlobalFollows = (item: string, status: boolean) => {
    setFollowedItems(
      followedItems.map((i) => {
        if (i.id == item) {
          return { id: i.id, followed: status };
        } else {
          return { ...i };
        }
      }),
    );
  };

  return (
    <div className="mx-auto flex max-w-4xl flex-col gap-3 px-3 py-4 lg:my-4 lg:rounded-lg lg:px-10 lg:py-8 lg:shadow">
      <Logo
        size="size-12"
        includeName={true}
      />
      <div className="flex w-full justify-between">
        <h1 className="text-2xl">Follow a Collection</h1>
      </div>
      <p>
        Tap the collections that interest you to get your cookbook started. You can add your own
        recipes later.
      </p>
      <ul
        role="list"
        className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2"
      >
        {collections.map((item) => {
          return (
            <Collection
              key={item.id}
              collection={item}
              setGlobalFollowed={trackGlobalFollows}
            />
          );
        })}
      </ul>
      {button}
    </div>
  );
}
