export type Theme = "system" | "light" | "dark";

export type SessionOptions = {
  authentication: {
    authenticationMethod: "email";
    canSignup: boolean;
  };
};

export type Session = {
  options: SessionOptions;
  user?: User;
};

export type SessionContextType = {
  session: Session;
  setSession: React.Dispatch<React.SetStateAction<Session>>;
};

export enum Page {
  Signin = "/signin",
  Signup = "/signup",
  ForgotPassword = "/forgot-password",
  Plan = "/plan",
  OnboardingAddCollections = "/onboarding/add-collections",
  OnboardingPlanLoading = "/onboarding/planning",
  Recipes = "/recipes",
  AddRecipe = "/add",

  SwapRecipe = "/menu/{menuDate}/recipes/{scheduledRecipeId}/swap",
}

export type Household = {
  id: string;
  title: string;
};

export enum OnboardingStatus {
  Done = "done",
  CreateHome = "create-home",
  StartCookbook = "start-cookbook",
  NeedsPlan = "needs-plan",
}

export type User = {
  displayName: string;
  email: string;
  id: string;
  selectedHousehold?: Household;
  households: Household[];
  onboardingStatus: OnboardingStatus;
  // The user context requires us to pass a default that should never be used in the application
  // UNLESS there is no parent provider in the tree. This allows us to indicate that the user was
  // not fetched from the backend but is in fact anonymous. It's better than being undefined
  // See docs here: https://reactjs.org/docs/context.html#reactcreatecontext
  isAnonymous: boolean;
};
