import { CheckIcon } from "@heroicons/react/20/solid";
import { Button } from "../components/button.tsx";
import { RecipeCourses, RecipeTags } from "./recipe-detail.tsx";
import { RecipeSummaryType } from "./recipe-list.lib.ts";

/**
 * Show details about a possible replacement recipe.
 */
export function Recipe({
  recipe,
  clickLabel,
  clickHandler,
}: {
  recipe: RecipeSummaryType;
  clickLabel: string;
  clickHandler: () => void;
}) {
  return (
    <div className="group flex flex-row items-start gap-4">
      {/* Image header */}
      {recipe.imageUrl && (
        <div className="w-20 group-hover:opacity-75 sm:w-40">
          <img
            className="rounded-md object-cover sm:aspect-[4/3]"
            src={recipe.imageUrl}
          />
        </div>
      )}
      {/* Recipe details box */}
      <div className="flex grow flex-col items-start justify-between gap-2 sm:flex-row">
        {/* Recipe attributes */}
        <div className="flex grow flex-col gap-1">
          <div className="text-lg font-bold text-theme-muted">{recipe.title}</div>
          <RecipeCourses courses={recipe.courses} />
          <RecipeTags tags={recipe.tags} />
        </div>
        {/* Buttons */}
        <div className="flex flex-row justify-end gap-x-2 text-nowrap">
          <Button
            color="light"
            onClick={clickHandler}
          >
            <CheckIcon /> {clickLabel}
          </Button>
        </div>
      </div>
    </div>
  );
}
