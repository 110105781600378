import { useLoaderData, useSubmit } from "react-router-dom";
import { BackButton } from "../components/back-button.tsx";
import { Heading } from "../components/heading.tsx";
import { usePageTitle } from "../utils";
import { Recipe } from "./select-recipe.tsx";
import { SwapRecipeResponseType, SwapTargetType } from "./swap-scheduled-recipe.lib.ts";

/**
 * Show details about the scheduled recipe that is being swapped out.
 */
function TargetDetails({ target }: { target: SwapTargetType }) {
  return (
    <div>
      <div>
        Select a replacement for <span className="font-semibold">{target.title}</span>, currently
        scheduled as a {target.course} for {target.mealTitle} on {target.mealDate}, at{" "}
        {target.mealTime}.
      </div>
    </div>
  );
}

export function SwapScheduledRecipePage() {
  const submit = useSubmit();
  const { swapTarget, recipes } = useLoaderData() as SwapRecipeResponseType;

  usePageTitle("Swap Recipe in Meal Schedule");

  return (
    <div>
      <div className="mb-4 flex flex-row justify-between">
        <Heading>Swap Recipe</Heading>
        <BackButton />
      </div>
      <div className="mb-4 rounded-lg bg-theme-muted shadow">
        <div className="p-4">
          <TargetDetails target={swapTarget} />
        </div>
      </div>
      <div className="space-y-4">
        {recipes.map((r) => (
          <Recipe
            key={r.id}
            recipe={r}
            clickLabel="Choose Recipe"
            clickHandler={() => {
              submit({ recipeId: r.id }, { method: "post", encType: "application/json" });
            }}
          />
        ))}
      </div>
    </div>
  );
}
