import { captureException } from "@sentry/react";
import { FormikHelpers } from "formik";
import { z } from "zod";
import { createResponseSchema, HttpMethod, request, Urls } from "../api";
import { OnboardingStatus, User } from "../types";

export interface SignupFormValues {
  email: string;
  name: string;
  password: string;
}

export const ResponseUserSchema = z.object({
  display: z.string(),
  email: z.string(),
  has_usable_password: z.boolean(),
  id: z.string(),
  households: z.array(
    z.object({
      id: z.string(),
      title: z.string(),
    }),
  ),
  onboarding_status: z.nativeEnum(OnboardingStatus),
});

export const SignupResponseSchema = createResponseSchema(
  z.object({
    methods: z.array(
      z.object({
        at: z.number(),
        email: z.string(),
        method: z.string(),
      }),
    ),
    user: ResponseUserSchema,
  }),
);

export async function submitSignupForm(
  values: SignupFormValues,
  actions: FormikHelpers<SignupFormValues>,
): Promise<User | undefined> {
  const resp = await request(HttpMethod.POST, Urls.Signup, values);
  const payload = await SignupResponseSchema.safeParseAsync(await resp.json());

  const badMessage = new Error("Your signup was not completed successfully");

  if (!payload.success) {
    captureException(payload.error);
    actions.setStatus(badMessage);
    return;
  }

  const errors = payload.data?.errors;
  if (errors) {
    errors.forEach(({ message, param }) => {
      if (param) {
        actions.setFieldError(param, message);
      }
    });

    actions.setStatus(badMessage);
    return;
  }

  const data = payload.data?.data;
  if (!data) {
    actions.setStatus(badMessage);
    return;
  }

  return {
    displayName: data.user.display,
    email: data.user.email,
    id: data.user.id,
    // TODO: This needs to be more robust
    selectedHousehold: data.user.households[0],
    households: data.user.households,
    onboardingStatus: data.user.onboarding_status,
    isAnonymous: false,
  };
}
