import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useNavigate } from "react-router";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { FormErrorMessage, FormikSubmitButton, TextInput } from "../components/fieldset.tsx";
import { Heading } from "../components/heading.tsx";
import { usePageTitle } from "../utils.ts";
import { AddRecipeFormValues, submitAddRecipeFromUrl } from "./add-recipe.lib.ts";

const initialValues: AddRecipeFormValues = { url: "" };

const AddRecipeSchema = toFormikValidationSchema(
  z.object({
    url: z.string({ required_error: "URL is required" }).url("A valid URL is required"),
  }),
);

export function AddRecipePage() {
  const navigate = useNavigate();
  usePageTitle("Add Recipe");

  async function onSubmit(
    values: AddRecipeFormValues,
    actions: FormikHelpers<AddRecipeFormValues>,
  ) {
    await submitAddRecipeFromUrl(values, actions, navigate);
  }

  return (
    <div className="container mx-auto">
      <div className="flex flex-row items-center justify-between gap-4">
        <Heading className="text-theme-normal">Add a Recipe</Heading>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={AddRecipeSchema}
        onSubmit={onSubmit}
      >
        {/*Note this is a function not a component*/}
        {AddRecipeForm}
      </Formik>
    </div>
  );
}

function AddRecipeForm(props: FormikProps<AddRecipeFormValues>) {
  return (
    <Form className="space-y-4">
      {props.status && <FormErrorMessage>{props.status.message}</FormErrorMessage>}
      <Field
        name="url"
        className="grow"
      >
        {({ field, meta }: FieldProps) => (
          <TextInput
            label="Please enter the location (URL) of the recipe that you want to add."
            inputProps={{ ...field, type: "text", autoComplete: "url" }}
            error={meta.touched && meta.error ? meta.error : undefined}
          />
        )}
      </Field>
      <div className="flex flex-row justify-between">
        <FormikSubmitButton
          title="Import"
          isSubmitting={props.isSubmitting}
        />
      </div>
    </Form>
  );
}
