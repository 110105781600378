import { useLoaderData, useSubmit } from "react-router-dom";
import { BackButton } from "../components/back-button.tsx";
import { Heading } from "../components/heading.tsx";
import { usePageTitle } from "../utils.ts";
import { AddScheduledRecipeResponseType } from "./add-scheduled-recipe.lib.ts";
import { Recipe } from "./select-recipe.tsx";

export function AddScheduledRecipePage() {
  const submit = useSubmit();
  const { recipes } = useLoaderData() as AddScheduledRecipeResponseType;

  usePageTitle("Add Recipe to Meal Schedule");

  return (
    <div data-testid="page:add-recipe">
      <div className="mb-4 flex flex-row items-center justify-between">
        <Heading>Add Recipe</Heading>
        <BackButton />
      </div>
      <div className="space-y-4">
        {recipes.map((r) => (
          <Recipe
            key={r.id}
            recipe={r}
            clickLabel="Choose Recipe"
            clickHandler={() => {
              submit({ recipeId: r.id }, { method: "post", encType: "application/json" });
            }}
          />
        ))}
      </div>
    </div>
  );
}
