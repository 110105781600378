import { Params } from "react-router-dom";
import { z } from "zod";
import { createResponseSchema, request as doRequest, HttpMethod, Urls } from "../api.ts";

export const RecipeIngredientSchema = z.object({
  id: z.string(),
  food: z.string(),
  quantity: z.coerce.number(),
  unit: z.string(),
  display: z.string(),
});
export type RecipeIngredientType = z.infer<typeof RecipeIngredientSchema>;

export const RecipeStepSchema = z.object({
  id: z.string(),
  index: z.number().nonnegative().int(),
  heading: z.string().nullable(),
  text: z.string(),
});
export type RecipeStepType = z.infer<typeof RecipeStepSchema>;

export const RecipeDetailSchema = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  sourceUrl: z.string().nullable(),
  prepTime: z.string().nullable(),
  cookTime: z.string().nullable(),
  totalTime: z.string().nullable(),
  yieldsServings: z.number().int().nullable(),
  courses: z.array(z.string()),
  imageUrl: z.string(),
  ingredients: z.array(RecipeIngredientSchema),
  steps: z.array(RecipeStepSchema),
  tags: z.array(z.string()),
});

export type RecipeDetailType = z.infer<typeof RecipeDetailSchema>;

/** Loader for the RecipeDetailPage page. */
export async function loader({
  params,
}: {
  params: Params<"recipeId">;
}): Promise<RecipeDetailType | undefined | Response> {
  const url = Urls.RecipeDetail.replace("{recipeId}", params.recipeId!);
  const resp = await doRequest(HttpMethod.GET, url);
  if (resp.status !== 200) {
    return resp;
  }
  const payload = await createResponseSchema(RecipeDetailSchema).safeParseAsync(await resp.json());

  if (payload.success) {
    return payload.data.data;
  }
}
