import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../components/loading";
import { Page, User } from "../types";
import { UserContext } from "../user-context";
import { planDatesAfterSignup } from "./onboarding.lib";

export function OnboardingPlanLoading() {
  const user: User = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.onboardingStatus == "done") {
      navigate(Page.Plan);
      return;
    }

    const createPlan = async () => {
      const resp = await planDatesAfterSignup(user.selectedHousehold?.id || "");
      const payload = await resp.json();

      if (payload.data.complete) {
        navigate(Page.Plan);
      }
    };

    createPlan();
  }, [navigate, user.onboardingStatus, user.selectedHousehold]);

  return <Loading ready={false} />;
}
