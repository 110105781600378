import { useEffect } from "react";
import { ulid } from "ulid";

export function createRandomPrefixedId(prefix: string): string {
  return `${prefix}_${ulid().toLowerCase()}`;
}

export function usePageTitle(title: string) {
  useEffect(() => {
    document.title = `${title} - MealsMatter`;
  }, [title]);
}
