import { captureException } from "@sentry/react";
import { FormikHelpers } from "formik";
import { HttpMethod, request, Urls } from "../api";
import { User } from "../types";
import { SignupResponseSchema } from "./signup.lib";

export interface SigninFormValues {
  email: string;
  password: string;
}

export const SigninResponseSchema = SignupResponseSchema;

export async function submitSigninForm(
  values: SigninFormValues,
  actions: FormikHelpers<SigninFormValues>,
): Promise<User | undefined> {
  const resp = await request(HttpMethod.POST, Urls.Signin, values);
  const payload = await SigninResponseSchema.safeParseAsync(await resp.json());
  const badMessage = new Error("Unable to signin at this time.");

  if (!payload.success) {
    captureException(payload.error);
    actions.setStatus(badMessage);
    return;
  }

  const errors = payload.data?.errors;
  if (errors) {
    errors.forEach(({ message, param }) => {
      if (param) {
        actions.setFieldError(param, message);
      }
    });

    actions.setStatus(badMessage);
    return;
  }

  const data = payload.data?.data;
  if (!data) {
    actions.setStatus(badMessage);
    return;
  }

  return {
    displayName: data.user.display,
    email: data.user.email,
    id: data.user.id,
    // TODO: This needs to be more robust
    selectedHousehold: data.user.households[0],
    households: data.user.households,
    onboardingStatus: data.user.onboarding_status,
    isAnonymous: false,
  };
}
