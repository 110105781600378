import { ChevronLeftIcon, LinkIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { Checkbox, CheckboxField } from "../components/checkbox.tsx";
import { Divider } from "../components/divider.tsx";
import { Field, Label } from "../components/fieldset.tsx";
import { Heading, Subheading } from "../components/heading.tsx";
import { OriginalRecipeLink } from "../components/link.tsx";
import { Select } from "../components/select.tsx";
import { Switch, SwitchField } from "../components/switch.tsx";
import { Page } from "../types.ts";
import { usePageTitle } from "../utils.ts";
import { CookMealResponseType, CookRecipeType, setScheduledRecipeState } from "./cook.lib.ts";
import { RecipeIngredientType, RecipeStepType } from "./recipe-detail.lib.ts";

/**
 * Display a recipe ingredient on the cook page.
 */
function CookIngredient({
  scheduledRecipeId,
  ing,
  initial,
}: {
  scheduledRecipeId: string;
  ing: RecipeIngredientType;
  initial: boolean;
}) {
  const [checked, setChecked] = useState(initial);

  async function onChange(value: boolean) {
    setChecked(value);
    await setScheduledRecipeState(scheduledRecipeId, ing.id, value);
  }

  return (
    <CheckboxField>
      <Checkbox
        checked={checked}
        onChange={onChange}
      ></Checkbox>
      <Label>{ing.display}</Label>
    </CheckboxField>
  );
}

/**
 * Display a recipe step on the cook page.
 */
function CookStep({
  scheduledRecipeId,
  step,
  initial,
  focused,
  setCurStep,
}: {
  scheduledRecipeId: string;
  step: RecipeStepType;
  initial: boolean;
  focused: boolean;
  setCurStep: (value: string) => void;
}) {
  const [checked, setChecked] = useState(initial);

  async function onChange(value: boolean) {
    setChecked(value);
    await setScheduledRecipeState(scheduledRecipeId, step.id, value);
  }

  return (
    <div className={clsx("rounded p-2", focused && "bg-theme-muted", checked && "line-through")}>
      <div onClick={() => setCurStep(step.id)}>
        <div className="absolute font-semibold">{step.index + 1}.</div>
        <div className="ms-6">{step.text}</div>
      </div>
      {focused && (
        <div className="text-end">
          <SwitchField className="inline-grid">
            <Label
              className="text-xs font-semibold uppercase"
              muted
            >
              done
            </Label>
            <Switch
              name="completed"
              checked={checked}
              onChange={onChange}
            />
          </SwitchField>
        </div>
      )}
    </div>
  );
}

/**
 * Display a recipe on the cook page.
 */
function CookRecipe({
  r,
  visible,
  getCheckedState,
}: {
  r: CookRecipeType;
  visible: boolean;
  getCheckedState: (srId: string, objId: string) => boolean;
}) {
  const [curStepId, setCurStep] = useState<string | null>(null);

  return (
    <div className={visible ? "" : "hidden"}>
      {/* Recipe heading. */}
      <div className="mt-4 flex flex-row items-center justify-between gap-4">
        <Heading className="text-theme-normal">{r.title}</Heading>
        {r.sourceUrl && <OriginalRecipeLink href={r.sourceUrl} />}
      </div>
      {/* Ingredient panel. Limited height, sticky at top of view. */}
      <div className="sticky top-0 z-10 bg-theme-normal">
        <Subheading className="mb-2 mt-2 pt-2">Ingredients</Subheading>
        <div className="scroller max-h-40 overflow-y-auto">
          <ul className="list-inside list-disc space-y-2">
            {r.ingredients.map((ing) => (
              <CookIngredient
                key={ing.id}
                scheduledRecipeId={r.scheduledRecipeId}
                ing={ing}
                initial={getCheckedState(r.scheduledRecipeId, ing.id)}
              />
            ))}
          </ul>
        </div>
        <Divider className="my-4" />
      </div>
      {/* Steps. */}
      <Subheading
        className="mb-2"
        onClick={() => setCurStep(null)}
      >
        Steps
      </Subheading>
      <ol className="list-inside list-decimal">
        {r.steps.map((step) => (
          <CookStep
            key={step.id}
            scheduledRecipeId={r.scheduledRecipeId}
            step={step}
            initial={getCheckedState(r.scheduledRecipeId, step.id)}
            focused={step.id === curStepId}
            setCurStep={setCurStep}
          />
        ))}
      </ol>
    </div>
  );
}

/**
 * Display the cook page, which is the interface used when preparing meals.
 */
export function CookPage() {
  const { meal, recipes, checkedItems } = useLoaderData() as CookMealResponseType;
  const [curRecipe, setCurRecipe] = useState<CookRecipeType>(recipes[0]);

  usePageTitle(`Cooking: ${curRecipe.title}`);

  function onRecipeSelect(srId: string) {
    const sr = recipes.find((r) => r.scheduledRecipeId === srId);
    if (sr !== undefined) {
      setCurRecipe(sr);
    }
  }

  function getCheckedState(srId: string, objId: string): boolean {
    return checkedItems[srId]?.includes(objId) || false;
  }

  return (
    <div>
      <Link
        to={Page.Plan}
        className="mb-4 flex"
      >
        <ChevronLeftIcon className="size-6" /> Back to Plan
      </Link>
      <div className="mb-4 flex flex-row items-center justify-between">
        <Link to={`/menu/${meal.date}`}>
          <Heading>
            <div className="flex items-baseline gap-2">
              Cooking {meal.title} <LinkIcon className="size-4" />
            </div>
          </Heading>
        </Link>
        <Subheading>
          {meal.dateDisplay}, {meal.timeDisplay}
        </Subheading>

      </div>
      {/* Recipe selector */}
      <Field>
        <Select
          name="recipe"
          onChange={(e) => onRecipeSelect(e.target.value)}
        >
          {recipes.map((r) => (
            <option
              key={r.scheduledRecipeId}
              value={r.scheduledRecipeId}
            >
              {r.title}
            </option>
          ))}
        </Select>
      </Field>
      {/*
        All recipes for the meal.
        Only the selected recipe is visible.
      */}
      {recipes.map((r) => (
        <CookRecipe
          key={r.scheduledRecipeId}
          r={r}
          visible={r === curRecipe}
          getCheckedState={getCheckedState}
        />
      ))}
    </div>
  );
}
