import { Field, FieldProps, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  FormErrorMessage,
  FormikSubmitButton,
  FormSuccessMessage,
  TextInput,
} from "../components/fieldset";
import { AuthPageHeader } from "../components/frame";
import { Page } from "../types";
import { PasswordResetValues, submitPasswordReset } from "./password-reset.lib";

function ResetPasswordForm(props: FormikProps<PasswordResetValues>) {
  return (
    <Form className="space-y-6">
      {props.status && props.status.message && (
        <FormErrorMessage>{props.status.message}</FormErrorMessage>
      )}

      {props.status && <FormSuccessMessage>{props.status}</FormSuccessMessage>}

      <Field name="key">
        {() => {
          return (
            <input
              type="hidden"
              value={props.values.key}
            />
          );
        }}
      </Field>

      <Field name="password">
        {({ field, meta }: FieldProps) => (
          <TextInput
            label="New Password"
            inputProps={{ ...field, type: "password", autoComplete: "new-password" }}
            error={meta.touched && meta.error ? meta.error : undefined}
          />
        )}
      </Field>

      <div>
        <FormikSubmitButton
          className="w-full"
          title="Reset Password"
          isSubmitting={props.isSubmitting}
        />
      </div>
    </Form>
  );
}

export function ResetPasswordPage() {
  const { key } = useParams() as { key: string };
  const navigate = useNavigate();
  return (
    <>
      <AuthPageHeader
        heading="Reset Password"
        extra="Enter your new password below"
      />
      <Formik
        initialValues={{ password: "", key: key }}
        validationSchema={toFormikValidationSchema(
          z
            .object({
              password: z.string({ required_error: "Enter your new password" }),
            })
            .required({ password: true }),
        )}
        onSubmit={async (
          values: PasswordResetValues,
          actions: FormikHelpers<PasswordResetValues>,
        ) => {
          if (await submitPasswordReset(values, actions)) {
            actions.setStatus("Your password has been reset. Redirecting you to the login page...");
            setTimeout(() => {
              navigate(Page.Signin);
            }, 3000);
          }
        }}
      >
        {ResetPasswordForm}
      </Formik>
    </>
  );
}
