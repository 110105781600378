import clsx from "clsx";

export function Divider({
  soft = false,
  className,
  ...props
}: { soft?: boolean } & React.ComponentPropsWithoutRef<"hr">) {
  return (
    <hr
      role="presentation"
      {...props}
      className={clsx(
        className,
        "w-full border-t",
        soft && "border-theme-normal/5",
        !soft && "border-theme-normal/10",
      )}
    />
  );
}

export function TitledDivider({ title, className }: { title: string; className?: string }) {
  return (
    <div className="relative">
      <div
        aria-hidden="true"
        className="absolute inset-0 flex items-center"
      >
        <div className={clsx(className, "w-full border-t border-theme-normal/20")} />
      </div>
      <div className="relative flex justify-start">
        <span className="bg-theme-normal pr-3 text-base font-semibold leading-6">{title}</span>
      </div>
    </div>
  );
}
