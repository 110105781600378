import * as Headless from "@headlessui/react";
import { CheckIcon, XCircleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import * as Formik from "formik";
import type React from "react";
import { PropsWithChildren } from "react";
import { Button } from "./button";
import { Input } from "./input";

export function Fieldset({
  className,
  ...props
}: { className?: string } & Omit<Headless.FieldsetProps, "as" | "className">) {
  return (
    <Headless.Fieldset
      {...props}
      className={clsx(className, "[&>*+[data-slot=control]]:mt-6 [&>[data-slot=text]]:mt-1")}
    />
  );
}

export function Legend({
  className,
  ...props
}: { className?: string } & Omit<Headless.LegendProps, "as" | "className">) {
  return (
    <Headless.Legend
      data-slot="legend"
      {...props}
      className={clsx(
        className,
        "text-base/6 font-semibold text-zinc-950 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-white",
      )}
    />
  );
}

export function FieldGroup({ className, ...props }: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div
      data-slot="control"
      {...props}
      className={clsx(className, "space-y-8")}
    />
  );
}

export function Field({
  className,
  ...props
}: { className?: string } & Omit<Headless.FieldProps, "as" | "className">) {
  return (
    <Headless.Field
      {...props}
      className={clsx(
        className,
        "[&>[data-slot=label]+[data-slot=control]]:mt-3",
        "[&>[data-slot=label]+[data-slot=description]]:mt-1",
        "[&>[data-slot=description]+[data-slot=control]]:mt-3",
        "[&>[data-slot=control]+[data-slot=description]]:mt-3",
        "[&>[data-slot=control]+[data-slot=error]]:mt-3",
        "[&>[data-slot=label]]:font-medium",
      )}
    />
  );
}

export function Label({
  className,
  muted,
  ...props
}: { className?: string; muted?: boolean } & Omit<Headless.LabelProps, "as" | "className">) {
  return (
    <Headless.Label
      data-slot="label"
      {...props}
      className={clsx(
        className,
        muted ? "text-theme-muted" : "text-theme-normal",
        "select-none text-base/6 data-[disabled]:opacity-50 sm:text-sm/6",
      )}
    />
  );
}

export function Description({
  className,
  ...props
}: { className?: string } & Omit<Headless.DescriptionProps, "as" | "className">) {
  return (
    <Headless.Description
      data-slot="description"
      {...props}
      className={clsx(
        className,
        "text-base/6 text-zinc-500 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-zinc-400",
      )}
    />
  );
}

export function FormErrorMessage(props: PropsWithChildren) {
  return (
    <div className="my-3 w-full rounded-sm border-l-4 border-red-400 bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon
            aria-hidden="true"
            className="h-5 w-5 text-red-400"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-red-400">{props.children}</p>
        </div>
      </div>
    </div>
  );
}

export function FormSuccessMessage(props: PropsWithChildren) {
  return (
    <div className="mx-auto my-3 w-full max-w-sm rounded-sm border-l-4 border-green-400 bg-green-50 p-4 lg:w-96">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckIcon
            aria-hidden="true"
            className="h-5 w-5 text-green-400"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-green-400">{props.children}</p>
        </div>
      </div>
    </div>
  );
}

export function ErrorMessage({
  className,
  ...props
}: { className?: string } & Omit<Headless.DescriptionProps, "as" | "className">) {
  return (
    <Headless.Description
      data-slot="error"
      {...props}
      className={clsx(
        className,
        "text-base/6 text-red-600 data-[disabled]:opacity-50 sm:text-sm/6 dark:text-red-500",
      )}
    />
  );
}

export function FormikSubmitButton({
  className,
  title,
  isSubmitting,
}: {
  className?: string;
  title: string;
  isSubmitting: boolean;
}) {
  return (
    <Button
      type="submit"
      disabled={isSubmitting}
      className={clsx(className, "flex justify-center hover:cursor-pointer")}
    >
      {title}
    </Button>
  );
}

export function TextInput({
  label,
  inputProps,
  error,
}: {
  label?: string;
  inputProps: Formik.FieldInputProps<string> & Omit<Headless.InputProps, "className">;
  error?: string;
}) {
  return (
    <Field>
      {label && <Label>{label}</Label>}
      <Input
        {...inputProps}
        invalid={error !== undefined}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Field>
  );
}
