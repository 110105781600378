import { FormikHelpers } from "formik";
import { NavigateFunction } from "react-router";
import { createResponseSchema, HttpMethod, request, Urls } from "../api";
import { Page } from "../types";
import { RecipeDetailSchema } from "./recipe-detail.lib";

export interface AddRecipeFormValues {
  url: string;
}

const ResponseSchema = createResponseSchema(RecipeDetailSchema);

export async function submitAddRecipeFromUrl(
  values: AddRecipeFormValues,
  actions: FormikHelpers<AddRecipeFormValues>,
  navigate: NavigateFunction,
) {
  const resp = await request(HttpMethod.POST, Urls.AddRecipeFromUrl, { sourceUrl: values.url });
  const payload = await ResponseSchema.safeParseAsync(await resp.json());

  if (!resp.ok) {
    actions.setStatus(
      new Error(
        "A problem occurred while trying to import your recipe. Our team will look into the problem and see what went wrong.",
      ),
    );
    return;
  }

  if (!payload.success || !payload.data.data) {
    actions.setStatus(
      new Error(
        "A problem occurred while trying to import your recipe. Our team will look into the problem and see what went wrong.",
      ),
    );
    return;
  }

  const recipeId = payload.data.data.id;
  navigate(Page.Recipes + "/" + recipeId); // TODO: Figure out a clean way to do this.
}
