import { Params, redirect } from "react-router-dom";
import { z } from "zod";
import { request as doRequest, HttpMethod, Urls } from "../api.ts";
import { ScheduledRecipeSchema } from "./plan.lib.ts";
import { RecipeSummarySchema } from "./recipe-list.lib.ts";

export const SwapTargetSchema = ScheduledRecipeSchema.merge(
  z.object({
    mealTitle: z.string(),
    mealDate: z.string(),
    mealTime: z.string(),
  }),
);

/** A scheduled recipe that also includes information about the meal. */
export type SwapTargetType = z.infer<typeof SwapTargetSchema>;

export const SwapRecipeResponseSchema = z.object({
  swapTarget: SwapTargetSchema,
  recipes: z.array(RecipeSummarySchema),
});

/** The response data for a swap recipe selector. */
export type SwapRecipeResponseType = z.infer<typeof SwapRecipeResponseSchema>;

/** Loader for the SwapRecipe page. */
export async function loader({
  params,
}: {
  params: Params<"menuDate" | "scheduledRecipeId">;
}): Promise<SwapRecipeResponseType | Response> {
  const url = Urls.SwapScheduledRecipe.replace("{scheduledRecipeId}", params.scheduledRecipeId!);
  const resp = await doRequest(HttpMethod.GET, url);
  if (resp.status !== 200) {
    return redirect(`/menu/${params.menuDate!}`);
  }

  const payload = await resp.json();
  return SwapRecipeResponseSchema.parse(payload.data);
}

/**
 * Action for the SwapRecipe page.
 *
 * Makes an API call to swap out the displayed scheduled recipe with another recipe.
 */
export async function action({
  params,
  request,
}: {
  params: Params<"menuDate" | "scheduledRecipeId">;
  request: Request;
}) {
  const url = Urls.SwapScheduledRecipe.replace("{scheduledRecipeId}", params.scheduledRecipeId!);
  const payload = await request.json();
  const resp = await doRequest(HttpMethod.POST, url, payload);
  if (!resp.ok) {
    // TODO: do something?
  }
  return redirect(`/menu/${params.menuDate!}`);
}
