import { z } from "zod";
import { HttpMethod, request, Urls } from "../api.ts";

export const ScheduledRecipeSchema = z.object({
  id: z.string(),
  recipeId: z.string(),
  title: z.string(),
  course: z.string(),
  servings: z.number().nullable(),
  totalTimeDisplay: z.string().nullable(),
  imageUrl: z.string(),
  schedulingMethod: z.string(),
});

/** A scheduled recipe. */
export type ScheduledRecipeType = z.infer<typeof ScheduledRecipeSchema>;

export const MealSchema = z.object({
  id: z.string(),
  title: z.string(),
  time: z.string().time(),
  timeDisplay: z.string(),
  recipes: z.array(ScheduledRecipeSchema),
  extraRecipesDisplay: z.string().nullable(),
});

/** A meal. */
export type MealType = z.infer<typeof MealSchema>;

export const DayMenuSchema = z.object({
  title: z.string(),
  subTitle: z.string(),
  date: z.string().date(),
  meals: z.array(MealSchema),
});

/** All meals and scheduled recipes for a specific day. */
export type DayMenu = z.infer<typeof DayMenuSchema>;

export async function loader(): Promise<DayMenu[] | Response> {
  const resp = await request(HttpMethod.GET, Urls.Plan);

  if (resp.status !== 200) {
    return resp;
  }

  const payload = await resp.json();
  return z.array(DayMenuSchema).parse(payload.data);
}
