import { z } from "zod";

export const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;
export const AUTH_BASE_URL = BACKEND_URL + "/api/auth/browser/v1";

export type APIErrorKind = "UnknownError" | "ClientError";

export type ErrorDetail = {
  message: string;
  code: string;
  param?: string;
};

export function createResponseSchema<DataType extends z.ZodTypeAny>(dataSchema: DataType) {
  return z.object({
    status: z.number(),
    data: z.optional(dataSchema),
    // TODO: We probably want a more descrete type here
    meta: z.any(),
    errors: z.optional(
      z
        .array(
          z.object({
            message: z.string(),
            code: z.string(),
            param: z.optional(z.string()),
          }),
        )
        .nullable(),
    ),
  });
}

export const Urls = Object.freeze({
  Config: AUTH_BASE_URL + "/config",
  CurrentSession: AUTH_BASE_URL + "/auth/session",

  AddRecipeFromUrl: BACKEND_URL + "/api/cookbook/recipes/add-from-url",
  RecipeDetail: BACKEND_URL + "/api/cookbook/recipes/{recipeId}",
  RecipeList: BACKEND_URL + "/api/cookbook/recipes",

  Plan: BACKEND_URL + "/api/planner/plan",
  DayMenu: BACKEND_URL + "/api/planner/menu/{menuDate}",
  RemoveScheduledRecipe: BACKEND_URL + "/api/planner/recipes/{scheduledRecipeId}/remove",
  SwapScheduledRecipe: BACKEND_URL + "/api/planner/recipes/{scheduledRecipeId}/swap",
  AddScheduledRecipe: BACKEND_URL + "/api/planner/meals/{mealId}/add",

  CookMeal: BACKEND_URL + "/api/kitchen/cook/{mealId}",
  CookState: BACKEND_URL + "/api/kitchen/recipes/{scheduledRecipeId}/state",

  // AllAuth uses "login" instead of "signin"
  Signin: AUTH_BASE_URL + "/auth/login",
  Signup: AUTH_BASE_URL + "/auth/signup",

  // AllAuth provides this through the non-api related endpoints hence the user of
  // BACKEND_URL
  Signout: BACKEND_URL + "/logout/",
});

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  DELETE = "DELETE",
}

export interface HttpOptions {
  method: HttpMethod;
  headers: Record<string, string>;
  body?: string;
  credentials?: "include";
}

function getCookie(name: string): string | null {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function getCSRFToken(): string | null {
  const value = getCookie("csrftoken");
  return value;
}

export type RequestExtras = {
  household?: string;
};

export async function request(
  method: HttpMethod,
  path: string,
  data?: unknown,
  extras: RequestExtras = {},
): Promise<Response> {
  const webHost = new URL(document.documentURI).host;
  const apiHost = new URL(path).host;
  const shoudIncludeCredentials = apiHost != webHost;

  const headers = new Headers();

  headers.append("accept", "application/json");

  const options: RequestInit = {
    method,
    credentials: shoudIncludeCredentials ? "include" : undefined,
    // We manually handle all redirects. For example if this returns a 302 which gets handed
    // back to a loader the loader will do the redirect.
    redirect: "manual",
  };

  const csrfToken = getCSRFToken();
  if (csrfToken && path !== Urls.Config) {
    headers.append("x-csrftoken", csrfToken);
  }

  if (extras.household) {
    headers.append("x-household-id", extras.household);
  }

  if (data !== undefined) {
    options.body = JSON.stringify(data);
    headers.append("Content-Type", "application/json");
  }

  options.headers = headers;
  const resp = await fetch(path, options);

  // TODO: Handle 401s
  // (https://github.com/pennersr/django-allauth/blob/main/examples/react-spa/frontend/src/lib/allauth.js#L130-L139)
  return resp;
}
