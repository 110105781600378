import { Link, useLoaderData } from "react-router-dom";
import { Heading } from "../components/heading.tsx";
import { usePageTitle } from "../utils.ts";
import { RecipeCourses, RecipeTags } from "./recipe-detail.tsx";
import { RecipeSummaryType } from "./recipe-list.lib.ts";

export function RecipeListPage() {
  usePageTitle("Recipes");
  const recipes = useLoaderData() as RecipeSummaryType[];

  return (
    <div>
      <Heading className="text-theme-normal">Recipes</Heading>
      {/* Based on: https://tailwindui.com/components/ecommerce/components/product-lists */}
      <div className="mt-6 grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {recipes.map((r) => (
          <div
            key={r.id}
            className="group relative"
          >
            <div className="h-40 w-full overflow-hidden rounded-md group-hover:opacity-75">
              <img
                alt={r.title}
                src={r.imageUrl}
                className="aspect-[4/3] h-full w-full object-cover object-center"
              />
            </div>
            <h3 className="mt-4">
              <Link to={`/recipes/${r.id}`}>{r.title}</Link>
            </h3>
            <RecipeCourses
              courses={r.courses}
              className="mt-2"
            />
            <RecipeTags
              tags={r.tags}
              className="mt-2"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
