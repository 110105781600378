import { ArrowPathIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import { useState } from "react";
import { Link, useLoaderData, useParams, useRevalidator } from "react-router-dom";
import { Alert, AlertActions, AlertDescription, AlertTitle } from "../components/alert.tsx";
import { BackButton } from "../components/back-button.tsx";
import { Button } from "../components/button.tsx";
import { Heading, Subheading } from "../components/heading.tsx";
import { Spinner } from "../components/spinner.tsx";
import { usePageTitle } from "../utils.ts";
import { removeRecipe } from "./day-menu.lib.ts";
import { DayMenu, MealType, ScheduledRecipeType } from "./plan.lib.ts";
import { RecipeCourses } from "./recipe-detail.tsx";

function Meal({ meal, date }: { meal: MealType; date: string }) {
  return (
    <div className="mb-8">
      <div className="mb-2 flex flex-row items-baseline">
        <Subheading level={3}>{meal.timeDisplay} -</Subheading>
        <Subheading
          level={3}
          className="ml-2 uppercase tracking-wide"
        >
          {meal.title}
        </Subheading>

        {meal.id && (
          <Link
            to={`/menu/${date}/meals/${meal.id}/add`}
            replace
            className="ml-4"
          >
            <PlusCircleIcon className="inline w-6" />
          </Link>
        )}
      </div>
      <div className="flex flex-col space-y-10">
        {meal.recipes.map((sr) => (
          <Recipe
            key={sr.id}
            mealId={meal.id}
            scheduledRecipe={sr}
            date={date}
          />
        ))}
      </div>
    </div>
  );
}

/**
 * Display recipe details.
 *
 * The layout breaks at `sm`.
 */
function Recipe({
  mealId,
  scheduledRecipe,
  date,
}: {
  mealId: string;
  scheduledRecipe: ScheduledRecipeType;
  date: string;
}) {
  const revalidator = useRevalidator();
  const [removalAlertIsOpen, setRemovalAlertIsOpen] = useState(false);
  const [removalInProgress, setRemovalInProgress] = useState(false);

  async function handleRemove() {
    setRemovalInProgress(true);
    await removeRecipe(scheduledRecipe.id);
    revalidator.revalidate();
    setRemovalAlertIsOpen(false);
    setRemovalInProgress(false);
  }
  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-4">
        <div>
          <Link to={`/cook/${mealId}?sr=${scheduledRecipe.id}`}>
            <img
              className="aspect-[4/3] h-24 max-h-24 w-24 max-w-24 rounded-md object-cover lg:h-48 lg:max-h-24 lg:w-24 lg:max-w-24"
              src={scheduledRecipe.imageUrl}
            />
          </Link>
        </div>
        <div className="flex grow flex-col justify-between gap-1">
          <div className="flex flex-col">
            <Link to={`/cook/${mealId}?sr=${scheduledRecipe.id}`}>
              <div className="text-lg font-bold text-theme-muted">{scheduledRecipe.title}</div>
            </Link>
            <RecipeCourses courses={[scheduledRecipe.course]} />
            {scheduledRecipe.totalTimeDisplay !== null && (
              <div>
                <span className="text-theme-muted">Cook time:</span>{" "}
                {scheduledRecipe.totalTimeDisplay}
              </div>
            )}
            {scheduledRecipe.servings !== null && (
              <div>
                <span className="text-theme-muted">Servings:</span> {scheduledRecipe.servings}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-4">
        <Button
          plain
          onClick={() => setRemovalAlertIsOpen(true)}
        >
          <TrashIcon className="size-10" /> Remove
        </Button>

        <Button
          plain
          href={`/menu/${date}/recipes/${scheduledRecipe.id}/swap`}
        >
          <ArrowPathIcon /> Swap
        </Button>
      </div>

      <Alert
        open={removalAlertIsOpen}
        onClose={setRemovalAlertIsOpen}
      >
        <AlertTitle>Are you sure you want to take this recipe off the menu?</AlertTitle>
        <AlertDescription>The recipe will be removed from the schedule.</AlertDescription>
        <AlertActions>
          <Button
            plain
            onClick={() => setRemovalAlertIsOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleRemove}
            disabled={removalInProgress} // Disabled during removal.
          >
            {removalInProgress && <Spinner />}
            Remove
          </Button>
        </AlertActions>
      </Alert>
    </div>
  );
}

export function DayMenuPage() {
  const dayMenu = useLoaderData() as DayMenu;
  const { menuDate } = useParams() as { menuDate: string };

  const dayTitle = `${dayMenu.title}’s Menu`;
  usePageTitle(dayTitle);

  return (
    <div data-testid="page:day-menu">
      <div className="mb-4 grid grid-cols-3 grid-rows-1 items-baseline">
        <div>
          <BackButton />
        </div>
        <Heading className="justify-self-center text-theme-normal">{dayTitle}</Heading>
        <Subheading className="justify-self-end text-2xl font-semibold text-theme-low-contrast">
          {dayMenu.subTitle}
        </Subheading>
      </div>

      {/* Meals */}
      {dayMenu.meals.map((meal) => (
        <Meal
          key={meal.id}
          meal={meal}
          date={menuDate}
        />
      ))}
    </div>
  );
}
