import { Square3Stack3DIcon, TagIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { useLoaderData } from "react-router-dom";
import { Badge } from "../components/badge.tsx";
import { Heading, Subheading } from "../components/heading.tsx";
import { OriginalRecipeLink } from "../components/link.tsx";
import { usePageTitle } from "../utils.ts";
import { RecipeDetailType, RecipeIngredientType, RecipeStepType } from "./recipe-detail.lib.ts";

export function RecipeCourses({ courses, className }: { courses: string[]; className?: string }) {
  if (courses.length === 0) {
    return null;
  }

  return (
    <div className={clsx(className, "flex flex-row flex-wrap items-center gap-2")}>
      <Square3Stack3DIcon className="size-4 text-theme-low-contrast" />
      {courses.map((course) => (
        <Badge
          key={course}
          color="yellow"
          className="font-bold"
        >
          {course}
        </Badge>
      ))}
    </div>
  );
}

export function RecipeTags({ tags, className }: { tags: string[]; className?: string }) {
  if (tags.length === 0) {
    return null;
  }
  return (
    <div className={clsx(className, "flex flex-row flex-wrap items-center gap-2")}>
      <TagIcon className="size-4 text-theme-low-contrast" />
      {tags.map((tag) => (
        <Badge
          key={tag}
          color="green"
          className="font-bold"
        >
          {tag}
        </Badge>
      ))}
    </div>
  );
}

export function Ingredient({ ing }: { ing: RecipeIngredientType }) {
  // TODO: Improve this.
  return <li>{ing.display}</li>;
}

export function Step({ step }: { step: RecipeStepType }) {
  return <li value={step.index + 1}>{step.text}</li>;
}

export function RecipeDetailPage() {
  usePageTitle("Recipe Detail");
  const r = useLoaderData() as RecipeDetailType | undefined;

  if (!r) {
    return <div>Unable to fetch recipe</div>;
  }

  return (
    <div className="container mx-auto">
      <div className="flex flex-row items-center justify-between gap-4">
        <Heading className="text-theme-normal">{r.title}</Heading>
        {r.sourceUrl && <OriginalRecipeLink href={r.sourceUrl} />}
      </div>
      {r.imageUrl && (
        <div className="mt-4">
          <img
            alt={r.title}
            src={r.imageUrl}
            className="aspect-[16/9] max-h-80 object-cover object-center"
          />
        </div>
      )}
      <div className="mt-4 flex flex-row justify-between">
        {r.totalTime !== null && <div>Cook time: {r.totalTime}</div>}
        {r.yieldsServings !== null && <div className="text-end">Servings: {r.yieldsServings}</div>}
      </div>
      <RecipeCourses
        courses={r.courses}
        className="mt-1"
      />
      <RecipeTags
        tags={r.tags}
        className="mt-1"
      />
      <Subheading className="mb-2 mt-4">Ingredients</Subheading>
      <ul className="list-inside list-disc space-y-1">
        {r.ingredients.map((ing) => (
          <Ingredient
            key={ing.id}
            ing={ing}
          />
        ))}
      </ul>
      <Subheading className="mb-2 mt-4">Steps</Subheading>
      <ol className="list-inside list-decimal space-y-1">
        {r.steps.map((step) => (
          <Step
            key={step.id}
            step={step}
          />
        ))}
      </ol>
    </div>
  );
}
