import * as Sentry from "@sentry/react";
import { createBrowserRouter, Navigate, Outlet, RouteObject } from "react-router-dom";
import { HttpMethod, request, Urls } from "./api";
import { AuthenticatedRoute } from "./components/auth";
import { ApplicationFrame, AuthFrame } from "./components/frame";
import { AddRecipePage } from "./pages/add-recipe.tsx";
import {
  action as addScheduledRecipeAction,
  loader as addScheduledRecipeLoader,
} from "./pages/add-scheduled-recipe.lib.ts";
import { AddScheduledRecipePage } from "./pages/add-scheduled-recipe.tsx";
import { loader as cookLoader } from "./pages/cook.lib.ts";
import { CookPage } from "./pages/cook.tsx";
import { loader as dayMenuLoader } from "./pages/day-menu.lib.ts";
import { DayMenuPage } from "./pages/day-menu.tsx";
import { ForgotPasswordPage } from "./pages/forgot-password";
import { NotFound } from "./pages/not-found.tsx";
import { AddCollectionsPage } from "./pages/onboarding-add-collections";
import { OnboardingPlanLoading } from "./pages/onboarding-plan-loading.tsx";
import { onboardingCollectionsLoader } from "./pages/onboarding.lib";
import { ResetPasswordPage } from "./pages/password-reset";
import { loader as planLoader } from "./pages/plan.lib.ts";
import { PlanPage } from "./pages/plan.tsx";
import { loader as recipeDetailLoader } from "./pages/recipe-detail.lib.ts";
import { RecipeDetailPage } from "./pages/recipe-detail.tsx";
import { loader as recipeListLoader } from "./pages/recipe-list.lib.ts";
import { RecipeListPage } from "./pages/recipe-list.tsx";
import { Root } from "./pages/root.tsx";
import { SigninPage } from "./pages/signin.tsx";
import { SignupPage } from "./pages/signup.tsx";
import {
  action as recipeSwapAction,
  loader as recipeSwapLoader,
} from "./pages/swap-scheduled-recipe.lib.ts";
import { SwapScheduledRecipePage } from "./pages/swap-scheduled-recipe.tsx";
import { Page, User } from "./types.ts";

async function getAppSession(): Promise<User | null> {
  const resp = await request(HttpMethod.GET, Urls.CurrentSession);

  if (resp.status !== 200) {
    return null;
  }

  const contents = await resp.json();

  return {
    displayName: contents.data.user.display,
    email: contents.data.user.email,
    id: contents.data.user.id,
    // TODO: This needs to be more robust
    selectedHousehold: contents.data.user.households[0],
    households: contents.data.user.households,
    onboardingStatus: contents.data.user["onboarding_status"],
    isAnonymous: false,
  };
}

export const routes: RouteObject[] = [
  {
    path: "/",
    id: "root",
    loader: getAppSession,
    element: (
      <Sentry.ErrorBoundary showDialog>
        <Root />
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        element: (
          <AuthenticatedRoute>
            <Outlet />
          </AuthenticatedRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate to={Page.Plan} />,
          },
          {
            path: "onboarding",
            children: [
              {
                path: "add-collections",
                id: "onboarding-add-collections",
                element: <AddCollectionsPage />,
                loader: async () => {
                  return onboardingCollectionsLoader();
                },
              },
              {
                path: "planning",
                element: <OnboardingPlanLoading />,
              },
            ],
          },

          {
            element: (
              <ApplicationFrame>
                <Outlet />
              </ApplicationFrame>
            ),
            children: [
              {
                id: "app:plan",
                path: "plan",
                element: <PlanPage />,
                loader: planLoader,
              },
              {
                path: "menu/:menuDate",
                element: <DayMenuPage />,
                loader: dayMenuLoader,
              },
              {
                path: "menu/:menuDate/recipes/:scheduledRecipeId/swap",
                element: <SwapScheduledRecipePage />,
                loader: recipeSwapLoader,
                action: recipeSwapAction,
              },
              {
                path: "menu/:menuDate/meals/:mealId/add",
                element: <AddScheduledRecipePage />,
                loader: addScheduledRecipeLoader,
                action: addScheduledRecipeAction,
              },
              {
                path: "cook/:mealId",
                element: <CookPage />,
                loader: cookLoader,
              },
              {
                path: "recipes",
                element: <RecipeListPage />,
                loader: recipeListLoader,
              },
              {
                path: "recipes/:recipeId",
                element: <RecipeDetailPage />,
                loader: recipeDetailLoader,
              },
              {
                path: "add",
                element: <AddRecipePage />,
              },
            ],
          },
        ],
      },
      {
        element: (
          <AuthFrame>
            <Outlet />
          </AuthFrame>
        ),
        children: [
          {
            path: "signin",
            element: <SigninPage />,
          },
          {
            path: "signup",
            element: <SignupPage />,
          },
          {
            path: "forgot-password",
            element: <ForgotPasswordPage />,
          },
          {
            path: "password-reset/:key",
            element: <ResetPasswordPage />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter(routes);
