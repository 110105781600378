import { Field, FieldProps, Form, Formik, FormikProps } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import {
  FormErrorMessage,
  FormikSubmitButton,
  FormSuccessMessage,
  TextInput,
} from "../components/fieldset";
import { AuthPageHeader } from "../components/frame";
import { Link } from "../components/link";
import { Page } from "../types";
import { ForgotPasswordValues, submitChangePassword } from "./forgot-password.lib";

function ForgotPasswordForm(props: FormikProps<ForgotPasswordValues>) {
  return (
    <Form className="space-y-6">
      {props.status && props.status.message && (
        <FormErrorMessage>{props.status.message}</FormErrorMessage>
      )}

      {props.status && <FormSuccessMessage>{props.status}</FormSuccessMessage>}

      <Field name="email">
        {({ field, meta }: FieldProps) => (
          <TextInput
            label="Email"
            inputProps={{ ...field, type: "email", autoComplete: "email" }}
            error={meta.touched && meta.error ? meta.error : undefined}
          />
        )}
      </Field>

      <div>
        <FormikSubmitButton
          className="w-full"
          title="Reset Password"
          isSubmitting={props.isSubmitting}
        />
      </div>
    </Form>
  );
}

export function ForgotPasswordPage() {
  return (
    <>
      <AuthPageHeader
        heading="Forgot Password"
        extra=<>
          Enter your email address below and a message with instructions will be sent.{" "}
          <Link to={Page.Signin}>Or sign in here</Link>
        </>
      />
      <Formik
        initialValues={{ email: "" }}
        validationSchema={toFormikValidationSchema(
          z
            .object({
              email: z.string({ required_error: "Email is required" }),
            })
            .required({ email: true }),
        )}
        onSubmit={submitChangePassword}
      >
        {ForgotPasswordForm}
      </Formik>
    </>
  );
}
