import { createContext } from "react";
import { OnboardingStatus, User } from "./types";

export const UserContext = createContext<User>({
  displayName: "",
  email: "",
  id: "",
  households: [],
  onboardingStatus: OnboardingStatus.Done,
  isAnonymous: true,
});
