import { z } from "zod";
import { request as doRequest, HttpMethod, Urls } from "../api.ts";

export const RecipeSummarySchema = z.object({
  id: z.string(),
  title: z.string(),
  courses: z.array(z.string()),
  tags: z.array(z.string()),
  totalTimeDisplay: z.string().nullable(),
  imageUrl: z.string(),
});

/** A recipe shown in a recipe list. */
export type RecipeSummaryType = z.infer<typeof RecipeSummarySchema>;

/** Loader for the RecipeDetailPage page. */
export async function loader(): Promise<RecipeSummaryType[] | Response> {
  const resp = await doRequest(HttpMethod.GET, Urls.RecipeList);
  if (resp.status !== 200) {
    return resp;
  }

  const payload = await resp.json();
  return z.array(RecipeSummarySchema).parse(payload.data);
}
