import { PropsWithChildren, useEffect, useState } from "react";
import { TightHeading } from "./heading";
import { Logo } from "./logo";

type DelayShowProps = {
  ready: boolean;
  delay?: number;
};

function LoadingPage() {
  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 items-center justify-center">
        <TightHeading className="animate-pulse">
          <div className="flex flex-col gap-2">
            <Logo size="size-20" /> Loading
          </div>
        </TightHeading>
      </div>
    </div>
  );
}

export function Loading({ ready, delay, children }: DelayShowProps & PropsWithChildren) {
  const [showLoading, setShowLoading] = useState(false);

  delay = delay ? delay : 750;

  useEffect(() => {
    const timer = setTimeout(() => setShowLoading(true), delay);

    return () => {
      clearTimeout(timer);
    };
  });

  return ready ? children : showLoading ? <LoadingPage /> : "";
}
